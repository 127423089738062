import { Button, Edit, required, SimpleForm, TextInput, useEditController, useRedirect, useRefresh } from "react-admin"
import { centsToUsd } from "../utils/format"
import { useParams } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { INSERT_TRANSACTION } from "../queries/transaction"
import { toast } from '../utils/toast';

export const RevertPayout = () => {
  const redirect = useRedirect()
  const refresh = useRefresh()
  const params = useParams()
  const { record } = useEditController({
    resource: "view_transaction_for_payout",
    id: params.id,
  })
  const [insertTxn] = useMutation(INSERT_TRANSACTION)

  const handleSubmit = async (values: any) => {
    const { revert_notes, id } = values
    const { debit, credit, amount_usd_cents } = record

    const params = {
      amount_usd_cents,
      // Swap debit <-> credit to make reversed txn
      debit: credit,
      credit: debit,
      description: revert_notes,
      type: "KOL_TRANSFER_REVERT",
      reverted_by: id,
    }

    insertTxn({
      variables: params,
    })
      .then((res) => {
        toast.success("Revert succeeded")
        redirect(`/view_transaction_for_payout`)

        // Give some time for data to be persisted before refetching
        setTimeout(() => {
          refresh()
        }, 500)
      })
      .catch((err) => {
        toast.error("Revert failed")
      })
  }

  return (
    <Edit>
      <SimpleForm onSubmit={handleSubmit as any} toolbar={false}>
        <TextInput disabled label="Debit wallet" source="debit" />
        <TextInput disabled label="Credit wallet" source="credit" />
        <TextInput disabled source="amount_usd_cents" label="Amount in USD" format={(x) => centsToUsd(x)} />
        <TextInput label="Notes" source="revert_notes" validate={required()} />
        <Button label="Revert" type="submit" size="large" color="primary" />
      </SimpleForm>
    </Edit>
  )
}
